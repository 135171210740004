import React, { useState, useEffect } from "react";
import axios from 'axios';
import params from "../config/Params";
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { useParams } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const Ratings = () => {
  const { listingId } = useParams();
  const [ratings, setRatings] = useState([]);

  useEffect(() => {      
    const fetchRatingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.ratingList}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
            setRatings(response.data.ratings);
        } else {
          console.log('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchRatingData();
  }, []);

  const CircularProgressBar = ({ percentage, value }) => {
    return (
      <div
        className="progress-circle"
        style={{ "--progress": `${percentage}%` }}
      >
        <span className="progress-text">{value}</span>
      </div>
    );
  };

  return (
    <div>
        <Helmet>
            <title>Listing Ratings</title>
            <meta name='description' content='' />
        </Helmet>
      
        <div className='yl-header'>
            <div>
                <h1 className='ws-title m-0'>Listing Ratings</h1>
            </div>
        </div>

        <div className="ap-offer-section">
            <div className="apoffbx">
                {ratings.length > 0 ? (
                  <div className='row'>
                    {ratings.find(r => r.case === "overall_rating") && (
                      <div className='col-md-12 lb'>
                        <div className="rating-box">
                          <div className="ovalrtng-box">
                            <CircularProgressBar percentage={(ratings.find(r => r.case === "overall_rating").count / 5) * 100} value={ratings.find(r => r.case === "overall_rating").count} />
                            <div>
                              <div className="rtnicntxt">
                                <img src={ratings.find(r => r.case === "overall_rating").img} alt="wire stay" className="img-fluid" />
                                <h4>{ratings.find(r => r.case === "overall_rating").title}</h4>
                              </div>
                              <p className="rating-score">{ratings.find(r => r.case === "overall_rating").count} / 5</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {ratings.filter(r => r.case !== "overall_rating").map((rating, index) => (
                      <div key={index} className='col-md-4 lb'>
                        <div className="rating-box">
                          <div className="rtnicntxt">
                            <img src={rating.img} alt="wire stay" className="img-fluid" />
                            <h4>{rating.title}</h4>
                          </div>
                          <div className="progress">
                            <div 
                              className="progress-bar bg-black" 
                              role="progressbar" 
                              style={{ width: `${(rating.count / 5) * 100}%`, position:'inherit' }}
                              aria-valuenow={rating.count} 
                              aria-valuemin="0" 
                              aria-valuemax="5">
                            </div>
                          </div>
                          <p className="rating-score">{rating.count} / 5</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No Ratings Found</p>
                )}
            </div>
        </div>
    </div>
  );
};

export default Ratings;
