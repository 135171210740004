import React, { useState, useEffect } from "react";
import axios from 'axios';
import params from '../../config/Params';
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import ScrollToTop from '../../ScrollToTop ';
import { useParams, useNavigate } from "react-router-dom";

const UpdateVideo = () => {
  const { videoId } = useParams();
  const [listingId, setListingId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDesc, setVideoDesc] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [videoStatus, setVideoStatus] = useState(null);
  const navigate = useNavigate();

    useEffect(() => {
        const fetchOfferData = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('video_id', videoId);
        
            try {
            const response = await axios({
                method: 'post',
                headers: {
                'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.getVideo}`,
                data: formData,
            });
        
            if (response.data.status === 'ok') {
                const selectedVideo = response.data.video;

                if (selectedVideo) {
                    setListingId(selectedVideo.stay_listing_id);
                    setVideoTitle(selectedVideo.video_title);
                    setVideoDesc(selectedVideo.video_desc);
                    setVideoFile(selectedVideo.video_file);
                    setVideoStatus(selectedVideo.video_status);
                    //console.log(selectedOffer);
                } else {
                    toast.error('Offer not found.');
                }
            } else {
                toast.error('An error occurred while fetching data.');
            }
            } catch (err) {
            console.log(err.message);
            }
        };
        fetchOfferData();
    }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const hostData = getItemFromLocalStorage('hostProfile');

    if (!hostData || !hostData.host_access_token) {
      toast.error('User is not authenticated.');
      return;
    }

    /*if (!listingId || !videoTitle || !videoDesc || !videoFile) {
      toast.error('Please fill in all the fields.');
      return;
    }*/

    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_video_id', videoId);
    formData.append('stay_listing_id', listingId);
    formData.append('video_title', videoTitle);
    formData.append('video_desc', videoDesc);
    formData.append('video_file', videoFile);
    formData.append('video_status', videoStatus);

    try {
      const response = await axios.post(
        `${params.baseURL}${params.endpoints.saveVideo}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response.data.status === 'ok') {
        toast.success('Video saved successfully!');
        // navigate('/hotel/promotions/active');
      } else {
        toast.error('Failed to save the offer.');
      }
    } catch (err) {
      console.error(err.message);
      toast.error('An error occurred while saving the offer.');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Featured Video</title>
        <meta name="description" content="Add a featured video to your listing." />
      </Helmet>

      <div className="video-section">
        <div className='row'>
          <div className='col-md-9'>
            <div className='clp-header'>
              <h1 className='ws-title'>Update Video</h1>
            </div>

            <form onSubmit={handleFormSubmit}>
              <div className="form-box">
                <label htmlFor="listingId" className="form-label">Listing ID</label>
                <input
                  type="text"
                  id="listingId"
                  className="form-control"
                  value={listingId}
                  onChange={(e) => setListingId(e.target.value)}
                  required
                />
              </div>

              <div className="form-box">
                <label htmlFor="videoTitle" className="form-label">Video Title</label>
                <input
                  type="text"
                  id="videoTitle"
                  className="form-control"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
              </div>

              <div className="form-box">
                <label htmlFor="videoDesc" className="form-label">Video Description</label>
                <textarea
                  id="videoDesc"
                  className="form-control"
                  value={videoDesc}
                  onChange={(e) => setVideoDesc(e.target.value)}
                  required
                ></textarea>
              </div>

              <div className="form-box">
                <label htmlFor="videoFile" className="form-label">Video File</label>
                <input
                  type="file"
                  id="videoFile"
                  className="form-control"
                  onChange={(e) => setVideoFile(e.target.files[0])}
                  required
                />
              </div>

              <div>
                <button type="submit" className="btn btn-black hlbtn">Update Video</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVideo;
