import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom'; 

const Videos = () => {
    const { listingId } = useParams();
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchListings = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('listing_id', listingId);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.videoVibeList}`,
                    data: formData
                });
            
                if (response.data.items && Array.isArray(response.data.items)) {
                    setListings(response.data.items);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchListings();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading listings: {error.message}</p>;
    }

    return (
        <div>
            <Helmet>
                <title>Videos</title>
                <meta name="description" content="" />
            </Helmet>
            <div className='yl-header'>
                <div>
                    <h1 className='ws-title m-0'>Videos</h1>
                </div>
            </div>

            <div className='advd-btn'>
                <a href={`/add-videos/${listingId}`}>Add Vibes Video</a>
            </div>

            {listings.length > 0 ? (
                <div className='row'>
                    {listings.map((listing) => (
                        <div className='col-md-4 lb mb-3' key={listing.id_tag_id}>
                            <div>
                                <div className='acc-box' style={{marginBottom:'10px'}}>
                                    <div className="apimgcibox">
                                        <div className='lcibox'>
                                            <div className='listing-cover-image mb-0'>
                                                <video className="ciso img-fluid" controls>
                                                    <source src={listing.video_origin} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='acc-box'>
                                    <span className='acc-btn-txt'>{listing.video_status_text}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No vibes video found</p>
            )}
        </div>
    );
};

export default Videos;
