import React, { useState, useEffect } from "react";
import axios from "axios";
import params from "../../../config/Params";
import { getItemFromLocalStorage } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import ScrollToTop from "../../../ScrollToTop ";

const Rooms = ({ formData, nextStep, prevStep }) => {

  const { listingId } = formData;
  const [rooms, setRooms] = useState([]);
  const [mealOptionList, setMealOptionList] = useState([]);
  const [roomViewList, setRoomViewList] = useState([]);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [bedTypeList, setBedTypeList] = useState([]);
  const [extraBedTypeList, setExtraBedTypeList] = useState([]);
  const [showRoomForm, setShowRoomForm] = useState(true);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [roomId, setRoomId] = useState(null);
  const [description, setDescription] = useState('');
  const [charLimit] = useState(500);
  const [roomName, setRoomName] = useState('');
  const [roomCount, setRoomCount] = useState(1);
  const [mealOption, setMealOption] = useState('');
  const [roomType, setRoomType] = useState('');
  const [bedType, setBedType] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [roomView, setRoomView] = useState('');
  const [smokingAllowed, setSmokingAllowed] = useState(1);
  const [extraBed, setExtraBed] = useState(2);
  const [extraBedType, setExtraBedType] = useState('');
  const [baseAdults, setBaseAdults] = useState(1);
  const [maxAdults, setMaxAdults] = useState(1);
  const [maxChildren, setMaxChildren] = useState(1);
  const [maxOccupancy, setMaxOccupancy] = useState(1);
  const [baseRate, setBaseRate] = useState('');
  const [extraAdultCharge, setExtraAdultCharge] = useState('');
  const [childCharges, setChildCharges] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [roomToDelete, setRoomToDelete] = useState(null);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);

  const [amenities, setAmenities] = useState([]);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [listingAmenity, setListingAmenity] = useState([]);
  const [highlightedAmenityIds, setHighlightedAmenityIds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setDescription(value);
    }
  };

  const handleIncrement = (value, setter, max = 10) => {
    if (value < max) {
      setter(value + 1);
    }
  };

  const handleDecrement = (value, setter, min = 1) => {
    if (value > min) {
      setter(value - 1);
    }
  };

  useEffect(() => {
    const fetchAmenities = async () => {
      const hostData = getItemFromLocalStorage("hostProfile");
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);

      setLoading(true);
      try {
        const response = await axios({
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          url: `${params.baseURL}${params.endpoints.getRoomAmenities}`,
          data: formData,
        });

        if (response.data.status === "ama") {
          const fetchedAmenities = response.data.ama_list;
          setAmenities(fetchedAmenities);
          if (fetchedAmenities.length > 0) {
            setSelectedAmenity(fetchedAmenities[0]);
          }
          setLoading(false);
        } else {
          setError("An error occurred while fetching data.");
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setRooms(response.data.updated_property_item.rooms || []);
          setShowRoomForm(response.data.updated_property_item.rooms === 0);

          setListingAmenity(response.data.updated_property_item.room_amanities);

          const savedCategories = response.data.updated_property_item.room_amanities.reduce((acc, item) => {
            acc[item.hma_id] = acc[item.hma_id] || {};
            acc[item.hma_id][item.hma_cat_id] = {
              value: item.hma_subcat_id ? "1" : "2",
              subCategory: item.hma_subcat_id || null,
            };
            return acc;
          }, {});
          setSelectedCategories(savedCategories);

        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const fetchHotelData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.hotelDataList}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const dataList = response.data.list;
          const mealOptions = dataList.find(item => item.meal_options)?.meal_options || [];
          const roomViewOptions = dataList.find(item => item.room_view)?.room_view || [];
          const roomTypeOptions = dataList.find(item => item.room_type)?.room_type || [];
          const bedTypeOptions = dataList.find(item => item.bed_type)?.bed_type || [];
          const extraBedTypeOptions = dataList.find(item => item.extra_bed_type)?.extra_bed_type || [];
          setMealOptionList(mealOptions);
          setRoomViewList(roomViewOptions);
          setRoomTypeList(roomTypeOptions);
          setBedTypeList(bedTypeOptions);
          setExtraBedTypeList(extraBedTypeOptions);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchAmenities();
    fetchListingData();
    fetchHotelData();
  }, []);  

  const handleAmenityClick = (amenity) => {
    setSelectedAmenity(amenity);
    setDropdownVisible((prev) => {
      const newDropdownVisible = {};
      Object.keys(selectedCategories[amenity.amenity_id] || {}).forEach((categoryId) => {
        const category = selectedCategories[amenity.amenity_id][categoryId];
        newDropdownVisible[categoryId] = category.value === "1" && category.subCategory;
      });
      return newDropdownVisible;
    });
  };

  const handleCategorySelect = (amenityId, categoryId, value, hasSubcategories) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [amenityId]: {
        ...prev[amenityId],
        [categoryId]: {
          value,
          subCategory: value === "1" ? prev[amenityId]?.[categoryId]?.subCategory || null : null,
        },
      },
    }));

    setDropdownVisible((prev) => ({
      ...prev,
      [categoryId]: value === "1" && hasSubcategories,
    }));
  };

  const handleSubCategorySelect = (amenityId, categoryId, subCategoryId) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [amenityId]: {
        ...prev[amenityId],
        [categoryId]: {
          ...prev[amenityId][categoryId],
          subCategory: subCategoryId,
        },
      },
    }));
  };

  const validateFields = () => {
    const validationErrors = {};
    if (!roomName || roomName.trim() === '') validationErrors.roomName = 'Please fill this input field';
    if (!baseRate) validationErrors.baseRate = 'Please fill this input field';
    if (!roomCount) validationErrors.roomCount = 'Please fill this input field';
    if (!mealOption) validationErrors.mealOption = 'Please select meal option';
    if (!bedType) validationErrors.bedType = 'Please select meal option';
    if (!roomType) validationErrors.roomType = 'Please select meal option';
    if (!roomSize || roomSize.trim() === '') validationErrors.roomSize = 'Please fill this input field';

    setErrors(validationErrors);
    //console.log(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSaveRoom = () => {
    const newRoom = {
      stay_listing_id : listingId,
        room_name : roomName,
        room_description : description,
        no_of_rooms : roomCount,
        meal_option: mealOption,
        room_type: roomType,
        bed_type: bedType,
        room_size: roomSize,
        room_view: roomView,
        is_smoking_allowed: smokingAllowed === "2",
        is_provide_extra_bed: extraBed,
        extra_bed_type: extraBedType,
        base_adults: baseAdults,
        maximum_adults: maxAdults,
        maximum_children: maxChildren,
        maximum_occupancy: maxOccupancy,
        base_room_price: baseRate,
        extra_adult_charge: extraAdultCharge,
        extra_child_charge: childCharges,
        booking_start_date: startDate,
        booking_end_date: endDate,
        room_status: 1
    };

    if (validateFields()) {
      setRooms([...rooms, newRoom]);
      setShowRoomForm(false);
      setCurrentRoomIndex(rooms.length);
      saveListing();
    }
  };

  const handleEditRoom = (index) => {
    const roomToEdit = rooms[index];
    setShowRoomForm(true);
    setCurrentRoomIndex(index);
    
    setRoomId(roomToEdit.hotel_room_id);
    setRoomName(roomToEdit.room_name);
    setRoomCount(roomToEdit.no_of_rooms);
    setMealOption(roomToEdit.meal_option);
    setRoomType(roomToEdit.room_type);
    setBedType(roomToEdit.bed_type);
    setRoomSize(roomToEdit.room_size);
    setRoomView(roomToEdit.room_view);
    setSmokingAllowed(roomToEdit.is_smoking_allowed);
    setExtraBedType(roomToEdit.extra_bed_type);
    setBaseRate(roomToEdit.base_room_price);

    const roomAmenities = listingAmenity.filter(
      (amenity) => amenity.hotel_room_id === roomToEdit.hotel_room_id
    );
  
    const roomCategories = roomAmenities.reduce((acc, item) => {
      acc[item.hma_id] = acc[item.hma_id] || {};
      acc[item.hma_id][item.hma_cat_id] = {
        value: item.hma_cat_id_status === 1 ? "1" : "2",
        subCategory: item.hma_subcat_id || null,
      };
      return acc;
    }, {});
  
    setSelectedCategories(roomCategories);
  
    const highlightedAmenityIds = Object.keys(roomCategories);
    setHighlightedAmenityIds(highlightedAmenityIds);
  
    const initialDropdownVisible = {};
    Object.keys(roomCategories).forEach((amenityId) => {
      Object.keys(roomCategories[amenityId]).forEach((categoryId) => {
        const category = roomCategories[amenityId][categoryId];
        if (category.value === "1" && category.subCategory) {
          initialDropdownVisible[categoryId] = true;
        }
      });
    });
    setDropdownVisible(initialDropdownVisible);

  };

  const handleDeleteRoom = async (index, roomId) => {
    try {
      await removeListingRoom(roomId);
      const updatedRooms = [...rooms];
      updatedRooms.splice(index, 1);
      setRooms(updatedRooms);
      setIsDeletePopupVisible(false);
      toast.success('Room successfully deleted.');
    } catch (error) {
      toast.error('Unable to delete the room. Please try again later.');
    }
  };

  const handleAddRoom = () => {
    setShowRoomForm(true);
    setCurrentRoomIndex(rooms.length);
    setSelectedCategories({});
    setDropdownVisible({});
    setHighlightedAmenityIds([]);
  };

  const removeListingRoom = async (roomId) => {
    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listingId);
    formData.append('room_id', roomId);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.removeRoom}`,
        data: formData,
      });

      if (response.data.status === 'done') {
        console.log('Room successfully deleted on server.');
      } else {
        console.log('Unable to delete your room. Please try after some time.');
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const openDeletePopup = (index, roomId) => {
    setRoomToDelete({ index, roomId });
    setIsDeletePopupVisible(true);
  };

  const closeDeletePopup = () => {
    setIsDeletePopupVisible(false);
    setRoomToDelete(null);
  };

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage("hostProfile");
    const ts = Date.now();

    const roomData = [
      {
        hotel_room_id : roomId,
        stay_listing_id : listingId,
        room_name : roomName,
        room_description : description,
        no_of_rooms : roomCount,
        meal_option: mealOption,
        room_type: roomType,
        bed_type: bedType,
        room_size: roomSize,
        room_view: roomView,
        is_smoking_allowed: smokingAllowed === "2",
        is_provide_extra_bed: extraBed,
        extra_bed_type: extraBedType,
        base_adults: baseAdults,
        maximum_adults: maxAdults,
        maximum_children: maxChildren,
        maximum_occupancy: maxOccupancy,
        base_room_price: baseRate,
        extra_adult_charge: extraAdultCharge,
        extra_child_charge: childCharges,
        booking_start_date: startDate,
        booking_end_date: endDate,
        room_status: 1
      },
    ];

    const formattedAmenities = Object.keys(selectedCategories).flatMap((amenityId) =>
      Object.keys(selectedCategories[amenityId]).map((categoryId) => ({
        //hotel_room_id : roomId,
        hma_id: amenityId,
        hma_cat_id: categoryId,
        hma_subcat_id: selectedCategories[amenityId][categoryId].subCategory || null,
        hma_cat_id_status: selectedCategories[amenityId][categoryId].value,
        stay_listing_id: listingId,
      }))
    );

    let catData = new FormData();
    catData.append("token", hostData.host_access_token);
    catData.append("listing_id", listingId);
    catData.append("data", JSON.stringify({rooms: roomData, room_amenities: formattedAmenities}));
    catData.append("ts", ts);

    try {
      const response = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });

      if (response.data.status === "done") {
        toast.success('Room Added Successfully.')
        //nextStep();
      } else {
        toast.error("Failed to save data");
      }
    } catch (err) {
      toast.error("An error occurred while saving the listing");
    }
  };

  const handleNext = () => {
    if (rooms.length > 0) {
      nextStep();
    }
    else {
      toast.error("Add atleast one room.");
    }
  };

  const handleRoomCountChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    setRoomCount(numericValue);
  };

  const faqs = [
    {
      question: "What details are required for room categories?",
      answer: "Provide room types (e.g., Deluxe, Suite), capacity, bed types, and additional features like balconies or views."
    },
    {
      question: "Can I upload images for each room type?",
      answer: "Yes, you should upload at least 3 images per room category to showcase them effectively."
    },
    {
      question: "How do I manage pricing for each room?",
      answer: "Pricing can be managed later from the calendar the dashboard, as well."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
        <ScrollToTop />
        <div className="hotel-register-section">
          <div className='row'>
            <div className="col-md-9">
              <div className="clp-header">
                  <h4 className='ws-title'>
                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                    Rooms
                  </h4>
                  <p className="ws-desc">
                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                    Define Rooms available at your property and the amenities for each type of room
                  </p>
              </div>

              {showRoomForm ? (
                <div>
                  <div className="add-room-section">

                    <div className="room-detail-box">

                      <div className="adrotibx" style={{marginBottom:'1rem'}}>
                        <h4 className='raus-title itwfdb'>
                          <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                          Rooms
                        </h4>
                        <p className="raus-desc itwfdb">
                          <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                          Add room level details
                        </p>
                      </div>
                      
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className="form-box">
                            <label for="roomName" class="form-label">Room Name</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="text" class="form-control" id="roomName" placeholder="Room Name" value={roomName} 
                                onChange={(e) => {
                                  setRoomName(e.target.value);
                                  if (errors.roomName && e.target.value.trim() !== '') {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.roomName;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              />
                            </div>
                            {errors.roomName && <div className="error-text">{errors.roomName}</div>}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='form-box'>
                            <label for="roomDesc" class="form-label">Room Description</label>
                            <textarea
                              className='form-control'
                              id='roomDesc'
                              rows={5}
                              value={description}
                              onChange={handleDescriptionChange}
                            />
                            <div className='hhtbs'>
                            {charLimit - (description?.length || 0)} characters available
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Number of Available Room(s)</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="text" class="form-control" id="roomCount" placeholder="Enter Room Count" value={roomCount} onChange={(e) => handleRoomCountChange(e.target.value)} />
                            </div>
                            {errors.roomCount && <div className="error-text">{errors.roomCount}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Meal Options</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <select class="form-select" value={mealOption}
                                onChange={(e) => {
                                  setMealOption(e.target.value);
                                  if (errors.mealOption && e.target.value.trim() !== '') {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.mealOption;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              >
                                  <option value="">select</option>
                                  {mealOptionList.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            {errors.mealOption && <div className="error-text">{errors.mealOption}</div>}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Room Type</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <select class="form-select" value={roomType} 
                                onChange={(e) => {
                                  setRoomType(e.target.value);
                                  if (errors.roomType && e.target.value.trim() !== '') {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.roomType;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              >
                                  <option selected>select</option>
                                  {roomTypeList.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            {errors.roomType && <div className="error-text">{errors.roomType}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Bed Type</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <select class="form-select" value={bedType} 
                                onChange={(e) => {
                                  setBedType(e.target.value);
                                  if (errors.bedType && e.target.value.trim() !== '') {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.bedType;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              >
                                  <option selected>select</option>
                                  {bedTypeList.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            {errors.bedType && <div className="error-text">{errors.bedType}</div>}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className="form-box">
                            <label htmlFor="roomSize" className="form-label">Room Size (Area in sqft)</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input
                                type="number"
                                className="form-control"
                                id="roomSize"
                                placeholder="Enter Room Size"
                                value={roomSize}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) { 
                                    setRoomSize(value);
                                    if (errors.roomSize && value.trim() !== '') {
                                      const updatedErrors = { ...errors };
                                      delete updatedErrors.roomSize;
                                      setErrors(updatedErrors);
                                    }
                                  }
                                }}
                              />
                            </div>
                            {errors.roomSize && <div className="error-text">{errors.roomSize}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Room View (Optional)</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <select class="form-select" value={roomView} onChange={(e) => setRoomView(e.target.value)}>
                                  <option selected>select</option>
                                  {roomViewList.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.title}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="roomCount" class="form-label">Smoking Allowed</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <select class="form-select" value={smokingAllowed} onChange={(e) => setSmokingAllowed(e.target.value)}>
                                  <option value="1">No</option>
                                  <option value="2">Yes</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="room-detail-box">
                      <div>
                        <p className='rdb-title'>Extra Bed</p>
                      </div>
                      <div className='dypxbs'>
                        <div>
                          <p>Do you provide extra bed?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className='exdrs'>
                            <label>
                              <input type="radio" name="extraBed" value="2" checked={extraBed === 2} onChange={() => setExtraBed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="extraBed" value="1" checked={extraBed === 1} onChange={() => setExtraBed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      {extraBed === 1 && (
                        <div className='row'>
                          <div className='col-md-6'>
                            <div class="form-box">
                              <label for="extraBed" class="form-label">Extra Bed Type</label>
                              <div className='itwac'>
                                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                                <select class="form-select" value={extraBedType} onChange={(e) => setExtraBedType(e.target.value)}>
                                    <option selected>select</option>
                                    {extraBedTypeList.map((list) => (
                                      <option key={list.id} value={list.id}>
                                        {list.title}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="room-detail-box">
                      <div>
                        <p className='rdb-title'>Room Occupancy</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='house-rules-box pt-0'>
                            <div className='rules-guest-box'>
                              <div className='hrbss'>
                                <label className='st1-label'>Base Adults</label>
                                <p className='label-hint'>Ideal number of adults that can be accomodated in this room. Occupancy calculations are based on the accommodation of two adults per room.</p>
                              </div>
                              <div className="input-number-container">
                                  <span className="input-number-decrement" onClick={() => handleDecrement(baseAdults, setBaseAdults)}>–</span>
                                  <input
                                      name='baseAdults'
                                      className="input-number"
                                      type="number"
                                      value={baseAdults}
                                      readOnly
                                  />
                                  <span className="input-number-increment" onClick={() => handleIncrement(baseAdults, setBaseAdults)}>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='house-rules-box'>
                            <div className='rules-guest-box'>
                              <div className='hrbss'>
                                <label className='st1-label'>Maximum Adults</label>
                                <p className='label-hint'>Maximum number of adults that can be accommodated in this room. Occupancy calculations are determined by the accommodation of two adults in a room, without the addition of extra beds.</p>
                              </div>
                              <div className="input-number-container">
                                  <span className="input-number-decrement" onClick={() => handleDecrement(maxAdults, setMaxAdults)}>–</span>
                                  <input
                                      name='maxAdults'
                                      className="input-number"
                                      type="number"
                                      value={maxAdults}
                                      readOnly
                                  />
                                  <span className="input-number-increment" onClick={() => handleIncrement(maxAdults, setMaxAdults)}>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='house-rules-box'>
                            <div className='rules-guest-box'>
                              <div className='hrbss'>
                                <label className='st1-label'>Maximum Children</label>
                                <p className='label-hint'>Mention the number of maximum children who are allowed to stay in the room</p>
                              </div>
                              <div className="input-number-container">
                                  <span className="input-number-decrement" onClick={() => handleDecrement(maxChildren, setMaxChildren)}>–</span>
                                  <input
                                      name='maxChildren'
                                      className="input-number"
                                      type="number"
                                      value={maxChildren}
                                      readOnly
                                  />
                                  <span className="input-number-increment" onClick={() => handleIncrement(maxChildren, setMaxChildren)}>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='house-rules-box'>
                            <div className='rules-guest-box'>
                              <div className='hrbss'>
                                <label className='st1-label'>Maximum Occupancy</label>
                                <p className='label-hint'>Specify the maximum number of adults & children that can be accommodated in this room</p>
                              </div>
                              <div className="input-number-container">
                                  <span className="input-number-decrement" onClick={() => handleDecrement(maxOccupancy, setMaxOccupancy)}>–</span>
                                  <input
                                      name='maxOccupancy'
                                      className="input-number"
                                      type="number"
                                      value={maxOccupancy}
                                      readOnly
                                  />
                                  <span className="input-number-increment" onClick={() => handleIncrement(maxOccupancy, setMaxOccupancy)}>+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="room-detail-box">
                      <div>
                        <p className='rdb-title'>Base Room Price</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="baseRate" class="form-label">Base Rate</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="text" class="form-control" id="baseRate" placeholder="Add Rate" value={baseRate} 
                                onChange={(e) => {
                                  setBaseRate(e.target.value);
                                  if (errors.baseRate && e.target.value.trim() !== '') {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.baseRate;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              />
                            </div>
                            {errors.baseRate && <div className="error-text">{errors.baseRate}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="extraAdultCharge" class="form-label">Extra Adult Charge (Optional)</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="text" class="form-control" id="extraAdultCharge" placeholder="Add Rate" value={extraAdultCharge} onChange={(e) => setExtraAdultCharge(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="childCharges" class="form-label">Charges for child (7-17 yrs) (Optional)</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="text" class="form-control" id="childCharges" placeholder="Add Rate" value={childCharges} onChange={(e) => setChildCharges(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="room-detail-box">
                      <div>
                        <p className='rdb-title mb-0'>Availability</p>
                        <p className='label-hint'>Please select the start & end dates on which your property can be booked by guests.</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="startDate" class="form-label">Start Date</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="date" class="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div class="form-box">
                            <label for="endDate" class="form-label">End Date</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="form-icon" />
                              <input type="date" class="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="room-detail-box">
                      <div>
                        <p className='rdb-title mb-0'>Room Amenities</p>
                        <p className='label-hint'>Answering the amenities available at your property can significantly influence guests to book! Please answer the <strong>Mandatory Amenities</strong> available below</p>
                      </div>
                      <div className="amen-section">
                        <div
                          style={{
                            width: "25%",
                            borderRight: "1px solid #ccc",
                            padding: "10px",
                            overflowY: "auto",
                          }}
                        >
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {amenities.map((amenity) => (
                              <li
                                key={amenity.amenity_id}
                                className="amen-list"
                                style={{
                                  backgroundColor:
                                    (selectedAmenity?.amenity_id === amenity.amenity_id ||
                                      highlightedAmenityIds.includes(amenity.amenity_id.toString()))
                                      ? "#cccccc"
                                      : "transparent",
                                }}
                                onClick={() => handleAmenityClick(amenity)}
                              >
                                <div className="amen-icon-box">
                                  <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cat-img" />
                                </div>
                                <span className="amen-title-box">{amenity.amenity_name}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div style={{ width: "75%", padding: "15px 10px" }}>
                          {selectedAmenity ? (
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                              {selectedAmenity.categories.map((category) => (
                                <li key={category.cat_id} className="amen-sublist">
                                  <div className="amen-sublist-box">
                                    <div className="amen-sublist-tcb">
                                      <div className="amen-icon-box">
                                        <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cat-img" />
                                      </div>
                                      <span>{category.cat_name}</span>
                                    </div>
                                    <div>
                                      <div className="extra-bed-radio">
                                        <div className="exdrs">
                                          <label>
                                            <input
                                              type="radio"
                                              name={`category_${category.cat_id}`}
                                              value="2"
                                              checked={
                                                selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.value === "2"
                                              }
                                              onChange={() =>
                                                handleCategorySelect(
                                                  selectedAmenity.amenity_id,
                                                  category.cat_id,
                                                  "2",
                                                  false
                                                )
                                              }
                                            />
                                            <span>No</span>
                                          </label>
                                          <label>
                                            <input
                                              type="radio"
                                              name={`category_${category.cat_id}`}
                                              value="1"
                                              checked={
                                                selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.value === "1"
                                              }
                                              onChange={() =>
                                                handleCategorySelect(
                                                  selectedAmenity.amenity_id,
                                                  category.cat_id,
                                                  "1",
                                                  category.subcategories.length > 0
                                                )
                                              }
                                            />
                                            <span>Yes</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {dropdownVisible?.[category.cat_id] &&
                                    selectedCategories?.[selectedAmenity?.amenity_id]?.[category.cat_id]?.value === "1" && (
                                      <>
                                        <div className="amen-visible-drop">
                                          <select
                                            className="form-select hrifb"
                                            style={{ fontSize: "14px" }}
                                            value={
                                              selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.subCategory || ""
                                            }
                                            onChange={(e) =>
                                              handleSubCategorySelect(
                                                selectedAmenity.amenity_id,
                                                category.cat_id,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select</option>
                                            {category.subcategories.map((subcategory) => (
                                              <option
                                                key={subcategory.sub_cat_id}
                                                value={subcategory.sub_cat_id}
                                              >
                                                {subcategory.sub_cat_name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </>
                                    )
                                  }
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p>Please select an amenity to view categories.</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div style={{display:'flex', gap:'20px'}}>
                      <button className='btn btn-black hlbtn' onClick={handleSaveRoom}>Save Room</button>
                      <button className='btn btn-black hlbtn' onClick={() => setShowRoomForm(false)}>Cancel</button>
                    </div>

                  </div>
                
                </div>
              ) : (
                <div>
                  {rooms.length > 0 ? (
                    rooms.map((room, index) => (
                      <div className="room-box" key={index}>
                        <div className="rbwnedb">
                          <p className="itwfdb">
                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                            {room.room_name}
                          </p>
                          <div className="room-action-btn-box">
                            <button onClick={() => handleEditRoom(index)}><i class="fas fa-pencil-alt"></i></button>
                            <button onClick={() => openDeletePopup(index,room.hotel_room_id)}><i class="fas fa-trash-alt"></i></button>
                          </div>
                        </div>
                        <div>
                          <p className="itwfdb">
                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                            Available Rooms : {room.no_of_rooms}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mb-3">
                      <img src={"/assets/img/no_room_available.png"} alt="" className="img-fluid" />
                    </div>
                  )}
                  <button className='btn btn-black hlbtn' onClick={handleAddRoom}>Add Room</button>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="faq-sticky">
                <div className='fas-desc-box'>
                  <div className='faq-title-box'>
                    <h4 className='faq-title-txt'>What to do here ?</h4>
                  </div>
                  <p className='faq-desc'>Let’s talk rooms—from cozy corners to luxury suites, it’s all in the details.</p>
                </div>
                <hr></hr>
                <div className='faq-txt-box'>
                  <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
                </div>
                {faqs.map((faq, index) => (
                  <div key={index} className='faqs-list-section'>
                    <div
                      onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                      style={{
                        backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                        borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                      }}
                    >
                      {faq.question}
                      <i
                        className={`fas ${
                          activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                        style={{
                          transition: "transform 0.3s ease",
                        }}
                      ></i>
                    </div>
                    {activeIndex === index && (
                      <div className='faqs-ans-box'>
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

        {/* Confirmation Popup */}
        {isDeletePopupVisible && (
          <div className="confirmation-popup-overlay">
            <div className="confirmation-popup">
              <h4>Confirm Delete</h4>
              <p>Are you sure you want to delete this room?</p>
              <div className="popup-actions">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteRoom(roomToDelete.index, roomToDelete.roomId)}
                >
                  Delete
                </button>
                <button className="btn btn-secondary" onClick={closeDeletePopup}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

    </div>
  );
};

export default Rooms;
