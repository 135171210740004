import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddVideos() {
    const { listingId } = useParams();
    const navigate = useNavigate();
    const [postType, setPostType] = useState(null);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [caption, setCaption] = useState('');
    const [loading, setLoading] = useState('');
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        if (imageList.length > 0) {
            setSelectedImage(imageList[0]);
        } else {
            setSelectedImage(null);
        }
    };

    const onImageClick = (image) => {
        setSelectedImage(image);
    };

    const onVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoFile(file);
            setVideoURL(videoURL);

            // Generate thumbnail
            const videoElement = document.createElement('video');
            videoElement.src = videoURL;
            videoElement.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                const thumbnailURL = canvas.toDataURL('image/jpeg');
                setThumbnail(thumbnailURL);
            });
        } else {
            setVideoFile(null);
            setVideoURL(null);
            setThumbnail(null);
        }
    };

    const onPostClick = async () => {
        if (!videoFile) {
            toast.error('Please upload a video.');
            return;
        }

        setLoading(true);

        const hostData = getItemFromLocalStorage('hostProfile');

        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', listingId);
        formData.append('listing_id', listingId);
        formData.append('video0', videoFile);
        if (thumbnail) {
            const thumbnailBlob = await fetch(thumbnail).then(res => res.blob());
            formData.append('image0', thumbnailBlob, 'thumbnail.jpg');
        }

        try {
            const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.saveVibeVideo}`,
                data: formData
            });

            console.log('Response:', response);

            if (response.data.status === 'completed') {
                setSelectedImage(null);
                setVideoFile(null);
                setVideoURL(null);
                setThumbnail(null);
                toast.success('Video uploaded succesfully.');
                navigate(`/videos/${listingId}`);
            } else if (response.data.status === 'error') {
                toast.error('Please complete your listing before proceeding.');
            } else {
                toast.error('Error in saving data.');
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="post">
            <Helmet>
                <title>Add Post</title>
            </Helmet>

            <div>
                <div className='lsertbox'>
                    <h4>Add Video</h4>
                </div>
                <div className='uploaded_image_box'>
                    {videoURL ? (
                        <div className="image-preview">
                            <video controls src={videoURL} />
                        </div>
                    ) : (
                        <div className="image-preview">
                            <img src={'/assets/img/icons/gallery_icon.svg'} alt="" />
                        </div>
                    )}
                </div>
                <div className="upload__image-wrapper">
                    <button
                        className='upload__btn'
                        onClick={() => document.getElementById('videoUpload').click()}
                    >
                        <img src={'/assets/img/icons/gallery_icon.svg'} alt='Upload Video' />
                    </button>
                    <input
                        type='file'
                        id='videoUpload'
                        style={{ display: 'none' }}
                        accept='video/*'
                        onChange={onVideoChange}
                    />
                    {thumbnail && (
                        <div className="image-item">
                        <img src={thumbnail} alt="" />
                        <div className="image-item__btn-wrapper">
                            <button className='img_delete_btn' >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    )}
                </div>
                <span className='side-line'></span>
            </div>

            <div style={{ margin: '1rem 0' }}>
                <button className='post_btn' onClick={onPostClick}>Post</button>
            </div>
        </div>
    );
}

export default AddVideos;
