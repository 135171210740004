import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import ScrollToTop from '../../../ScrollToTop ';

const Legal = ({ formData, nextStep, prevStep }) => {
  const { listingId } = formData;
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const [ownershipTypeList, setOwnershipTypeList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [ownertype, setOwnertype] = useState('');
  const [ownershipDoc, setOwnershipDoc] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [reAccountNumber, setReAccountNumber] = useState('');
  const [isMatch, setIsMatch] = useState(null);
  const [ifscNumber, setIfscNumber] = useState('');
  const [isIfscValid, setIsIfscValid] = useState(null);
  const [bankName, setBankName] = useState('');
  const [isGstin, setIsGstin] = useState(1);
  const [gstNumber, setGstNumber] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [isTanNumber, setIsTanNumber] = useState(1);
  const [tanNumber, setTanNumber] = useState('');
  const [isGstValid, setIsGstValid] = useState(null);
  const [isPanValid, setIsPanValid] = useState(null);
  const [isTanValid, setIsTanValid] = useState(null);
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isBankListVisible, setIsBankListVisible] = useState(true);

  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [paymentOption, setPaymentOption] = useState(2);

  const faqs = [
    {
      question: "What financial details are required?",
      answer: "Provide your bank account details for payouts and indicate whether you are GST registered."
    },
    {
      question: "What legal documents are needed?",
      answer: "Upload property ownership proof, a government-issued license (if applicable), and identification proof."
    },
    {
      question: "How is GST information used?",
      answer: "GST details are used to generate invoices for bookings and ensure compliance with tax laws."
    },
    {
      question: "What if I don’t have GST registration?",
      answer: "You can proceed without GST details, but invoices will be generated without GST components."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchLegal = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);
      formData.append("listing_id", listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === "ok") {
          const data = response.data.updated_property_item.legal;
          setOwnertype(data.ownership_type);
          setOwnershipDoc(data.ownership_document);
          setAccountNumber(data.owner_bank_account_number);
          setReAccountNumber(data.owner_bank_account_number);
          setIfscNumber(data.owner_bank_ifsc_number);
          setBankName(data.owner_bank_name);
          setIsGstin(data.is_gstin);
          setGstNumber(data.gstin_number);
          setPanNumber(data.pan_number);
          setIsTanNumber(data.is_tan_number);
          setTanNumber(data.tan_number);
          setPaymentOption(response.data.updated_property_item.payment_option);
        } else {
          toast.error("Failed to fetch policies data.");
        }
      } catch (error) {
        //toast.error("An error occurred while fetching data.");
        console.error(error);
      }
    };

    const fetchHotelData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.hotelDataList}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const dataList = response.data.list;
          const ownershipTypeOptions = dataList.find(item => item.ownership_type)?.ownership_type || [];
          const paymentOptionData = dataList.find(item => item.payment_options)?.payment_options || [];
          setOwnershipTypeList(ownershipTypeOptions);
          setPaymentOptionList(paymentOptionData);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const fetchBankList = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getBankList}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setBankList(response.data.bank_list);
          setFilteredBanks(response.data.bank_list);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchLegal();
    fetchHotelData();
    fetchBankList();
  }, [listingId, params]);

  const handleBankClick = (bank) => {
    setSelectedBank(bank);
    setBankName(bank.bank_name);
    setIsBankListVisible(false);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = bankList.filter((bank) =>
      bank.bank_name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredBanks(filtered);
    setShowDropdown(true);
  };

  const handleSelectBank = (bank) => {
    handleBankClick(bank);
    setSearchTerm(bank.bank_name);
    //setShowDropdown(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 15 * 1024 * 1024) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
    } else {
      alert("File size exceeds 15 MB or invalid format.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file && file.size <= 15 * 1024 * 1024) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
    } else {
      alert("File size exceeds 15 MB or invalid format.");
    }
  };

  const clearFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
  };

  const validateField = (field, value) => {
    const errors = {};
  
    switch (field) {
      case 'accountNumber':
        if (!value || value.length < 10) {
          errors[field] = 'Invalid account number. It must be at least 10 characters.';
        }
        break;
      case 'reAccountNumber':
        if (value !== accountNumber) {
          errors[field] = 'Account numbers do not match!';
        }
        break;
      case 'ifscNumber':
        const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!value || !ifscRegex.test(value)) {
          errors[field] = 'Invalid IFSC code.';
        }
        break;
      case 'gstNumber':
        const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/;
        if (!value || !gstRegex.test(value)) {
          errors[field] = 'Invalid GSTIN. It must be 15 characters.';
        }
        break;
      case 'panNumber':
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
        if (!value || !panRegex.test(value)) {
          errors[field] = 'Invalid PAN. It must be 10 characters.';
        }
        break;
      case 'tanNumber':
        const tanRegex = /^[A-Z]{4}[0-9]{5}[A-Z]$/;
        if (!value || !tanRegex.test(value)) {
          errors[field] = 'Invalid TAN. It must be 10 characters.';
        }
        break;
      case 'bankName':
        if (!value || value.trim() === '') {
          errors[field] = 'Bank name is required.';
        }
        break;
      default:
        break;
    }
  
    return errors;
  };
  
  const handleInputChange = (field, value) => {
    const updatedErrors = { ...errors };
    delete updatedErrors[field];
  
    const fieldErrors = validateField(field, value);
  
    setErrors({ ...updatedErrors, ...fieldErrors });
  
    // Update the corresponding state
    switch (field) {
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'reAccountNumber':
        setReAccountNumber(value);
        break;
      case 'ifscNumber':
        setIfscNumber(value);
        break;
      case 'gstNumber':
        setGstNumber(value);
        break;
      case 'panNumber':
        setPanNumber(value);
        break;
      case 'tanNumber':
        setTanNumber(value);
        break;
      case 'bankName':
        setBankName(value);
        break;
      default:
        break;
    }
  };
  
  const validateInputs = () => {
    const fieldsToValidate = [
      { field: 'accountNumber', value: accountNumber },
      { field: 'reAccountNumber', value: reAccountNumber },
      { field: 'ifscNumber', value: ifscNumber },
      { field: 'panNumber', value: panNumber },
      { field: 'bankName', value: bankName },
    ];

    if (isGstin === 1) {
      fieldsToValidate.push({ field: 'gstNumber', value: gstNumber });
    }
  
    if (isTanNumber === 1) {
      fieldsToValidate.push({ field: 'tanNumber', value: tanNumber });
    }
  
    const validationErrors = fieldsToValidate.reduce((acc, { field, value }) => {
      const errors = validateField(field, value);
      return { ...acc, ...errors };
    }, {});
  
    setErrors(validationErrors);
  
    return Object.keys(validationErrors).length === 0;
  };  

  const saveData = async () => {
    if (!validateInputs()) {
      toast.error("Please fill all mandatory fields.");
      return;
    }
    const hostData = getItemFromLocalStorage('hostProfile');
    const legalData = [{
      stay_listing_id : listingId,
      ownership_type : ownertype,
      //ownership_document : ownershipDoc,
      owner_bank_account_number : accountNumber,
      owner_bank_ifsc_number : ifscNumber,
      owner_bank_name : bankName,
      is_gstin : isGstin,
      gstin_number : gstNumber,
      pan_number : panNumber,
      is_tan_number : isTanNumber,
      tan_number : tanNumber
    }];

    let formData = new FormData();
    formData.append("token", hostData.host_access_token);
    formData.append("listing_id", listingId);
    formData.append("data", JSON.stringify({ payment_option: paymentOption, legal: legalData }));
    if (selectedFile) {
      formData.append("ownership_document", selectedFile);
    }

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: formData,
      });

      if (response.data.status === "done") {
        toast.success("Listing created successfully!");
        navigate('/my-listings');
        //nextStep();
      } else {
        toast.error("Failed to save policies.");
      }
    } catch (error) {
      toast.error("An error occurred while saving policies.");
      console.error(error);
    }
  };

  const handleNext = () => {
    saveData();
  };

  return (
    <div>
        <ScrollToTop />
        <div className="hotel-register-section legal-section">
          <div className='row'>
            <div className='col-md-9'>
              <div className="clp-header">
                <img src="/assets/img/banners/legal_text_banner_1.png" class="img-fluid" />
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Ownership details</p>
                  <p className="label-hint">Provide documents that prove the type of ownership the property has.</p>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div class="form-box" style={{border:'none',padding:'0'}}>
                      <div className='itwac'>
                        <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                        <select class="form-select" value={ownertype} onChange={(e) => setOwnertype(e.target.value)}>
                          {ownershipTypeList.map((list) => (
                            <option key={list.id} value={list.id}>
                              {list.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <p className="mb-0 file-label">Upload property registration document</p>
                    <p className="label-hint">Ensure the document's address matches the property address.</p>
                    <div
                      className={`upload-box ${filePreview ? "has-file" : ""}`}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      {filePreview ? (
                        <div className="file-preview">
                          {selectedFile.type.startsWith("image/") ? (
                            <img src={filePreview} alt="Preview" className="img-preview" />
                          ) : (
                            <p className="file-preview-text">Preview not available for PDF files.</p>
                          )}
                          <button type="button" className="btn-clear" onClick={clearFile}>
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <div className="upload-instructions">
                          <label htmlFor="fileInput" className="upload-label">
                            <span><img src='/assets/img/icons/doc_upload_icon.svg' className='img-fluid' alt='' /></span>
                          </label>
                          <p className='text-uppercase'>Drag & Drop or click here</p>
                          <p className="file-hint">(Upload only PDF file of up to 5 MB)</p>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            accept="application/pdf"
                            onChange={handleFileChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="add-room-section">
                <div className="room-detail-box">
                  <div>
                    <p className='rdb-title mb-0'>Banking Details</p>
                    <p className="label-hint">Enter Bank, PAN & GST Details</p>
                  </div>
                  <div className='row'>

                    <div className="bank-search-bar">
                      <div className='itwac'>
                        {/*<img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />*/}
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          placeholder="search bank"
                          className="form-control"
                          onFocus={() => {
                            setShowDropdown(true);
                            setIsBankListVisible(false);
                          }}
                          //onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                        />
                      </div>

                      {showDropdown && (
                        <div className="star-rating-popup">
                          <div className='star-popup-content'>
                            <div>
                              <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="search bank"
                                className="form-control"
                                style={{marginBottom:'1rem'}}
                                onFocus={() => {
                                  setShowDropdown(true);
                                  setIsBankListVisible(false);
                                }}
                                //onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                              />
                            </div>
                            <div className='banklist-popup-section'>
                              {filteredBanks.length > 0 ? (
                                filteredBanks.map((bank) => (
                                  <div
                                    key={bank.bank_id}
                                    className="bank-dropdown-item"
                                    onClick={() => handleSelectBank(bank)}                    
                                    aria-label={`Select ${bank.bank_name}`}
                                  >
                                    <div className='dbank-img-box'>
                                      <img src={bank.bank_image_url} className='img-fluid dbank-icon' />
                                      <div class="tooltiptext">{bank.bank_full_name}</div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="dropdown-item">No banks found</div>
                              )}
                            </div>
                            <div className='banklist-popup-footer'>
                              <button className='pp-btn btn-cancel' onClick={() => setShowDropdown(false)}>Close</button>
                              <button
                                className='pp-btn btn-done'
                                onClick={() => {
                                  if (searchTerm) {
                                    setShowDropdown(false);
                                  } else {
                                    alert('Please select a bank.');
                                  }
                                }}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {isBankListVisible && (
                    <div className="bank-list">
                      <p className='rdb-title'>Choose bank</p>
                      <div className="bank-list-section">
                        {bankList.map((bank) => (
                          <div key={bank.bank_id} className="bank-list-box">
                            <div
                              className='bank-it-section'
                              onClick={() => {
                                handleBankClick(bank);
                                setIsBankListVisible(false);
                              }}
                              aria-label={`Select ${bank.bank_name}`}
                            >
                              <div className='dbank-img-box'>
                                <img src={bank.bank_image_url} className='img-fluid dbank-icon' />
                                <div class="tooltiptext">{bank.bank_full_name}</div>
                              </div>
                              <span>{bank.bank_name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    )}

                    {selectedBank && (
                      <div className="bank-details-form">
                        <div className='selected-bank-details'>
                          <img src={selectedBank.bank_image_url} className='img-fluid' />
                          <span>{selectedBank.bank_name}</span>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-box">
                              <label htmlFor="accountNumber" className="form-label">
                                Account Number
                              </label>
                              <div className='itwac'>
                                <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="accountNumber"
                                  placeholder="Enter Account Number"
                                  value={accountNumber}
                                  onChange={(e) => setAccountNumber(e.target.value)}
                                  onPaste={(e) => e.preventDefault()}
                                  required
                                />
                              </div>
                              {errors.accountNumber && (
                                <div className="error-text">{errors.accountNumber}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-box">
                              <label htmlFor="reAccountNumber" className="form-label">
                                Re-enter Account Number
                              </label>
                              <div className='itwac'>
                                <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                                <input
                                  type="text"
                                  className={`form-control ${
                                    isMatch === false ? "is-invalid" : ""
                                  }`}
                                  id="reAccountNumber"
                                  placeholder="Re-enter Account Number"
                                  value={reAccountNumber}
                                  onChange={(e) => {
                                    setReAccountNumber(e.target.value);
                                    setIsMatch(e.target.value === accountNumber);
                                  }}
                                  onPaste={(e) => e.preventDefault()}
                                />
                              </div>
                              {isMatch === false && (
                                <div className="invalid-feedback">
                                  Account numbers do not match!
                                </div>
                              )}
                              {errors.reAccountNumber && (
                                <div className="error-text">{errors.reAccountNumber}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-box">
                              <label htmlFor="ifsc" className="form-label">
                                IFSC Number
                              </label>
                              <div className='itwac'>
                                <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                                <input
                                  type="text"
                                  className={`form-control ${
                                    isIfscValid === false ? "is-invalid" : ""
                                  }`}
                                  id="ifsc"
                                  placeholder="Enter IFSC Number"
                                  value={ifscNumber}
                                  onChange={(e) => setIfscNumber(e.target.value)}
                                />
                              </div>
                              {isIfscValid === false && (
                                <div className="invalid-feedback">
                                  Please enter a valid IFSC code.
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className="form-box">
                              <label for="bankName" class="form-label">Bank Name</label>
                              <div className='itwac'>
                                <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                                <input type="text" class="form-control" id="bankName" placeholder="Enter Bank Name" value={bankName} 
                                onChange={(e) => handleInputChange('bankName', e.target.value)}
                              />
                              </div>
                              {errors.bankName && <div className="error-text">{errors.bankName}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <span className='line'></span>

                  <div className="room-detail-box">
                    <div className='dypxbs'>
                      <div>
                        <p>Do you have a GSTIN?</p>
                      </div>
                      <div class="extra-bed-radio">
                        <div className='exdrs'>
                          <label>
                            <input type="radio" name="isGstin" value="2" checked={isGstin === 2} onChange={() => setIsGstin(2)} />
                            <span>No</span>
                          </label>
                          <label>
                            <input type="radio" name="isGstin" value="1" checked={isGstin === 1} onChange={() => setIsGstin(1)} />
                            <span>Yes</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {isGstin === 1 && (
                        <div className='col-md-6'>
                          <div className="form-box">
                            <label htmlFor="gstin" className="form-label">Enter GSTIN</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                              <input 
                                type="text" 
                                className={`form-control ${isGstValid === false ? 'is-invalid' : ''}`} 
                                id="gstin" 
                                placeholder="Enter the 15 digit GSTIN" 
                                value={gstNumber} 
                                onChange={(e) => handleInputChange('gstNumber', e.target.value)}
                              />
                            </div>
                            {isGstValid === false && (
                              <div className="invalid-feedback">Please enter a valid GSTIN (15 characters).</div>
                            )}
                            {errors.gstNumber && <div className="error-text">{errors.gstNumber}</div>}
                          </div>
                        </div>
                      )}
                      <div className='col-md-6'>
                        <div className="form-box">
                          <label htmlFor="pancard" className="form-label">Enter PAN</label>
                          <div className='itwac'>
                            <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                            <input 
                              type="text" 
                              className={`form-control ${isPanValid === false ? 'is-invalid' : ''}`} 
                              id="pancard" 
                              placeholder="Enter PAN" 
                              value={panNumber} 
                              onChange={(e) => handleInputChange('panNumber', e.target.value)}
                            />
                          </div>
                          {isPanValid === false && (
                            <div className="invalid-feedback">Please enter a valid PAN (10 characters).</div>
                          )}
                          {errors.panNumber && <div className="error-text">{errors.panNumber}</div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className='line'></span>

                  <div className="room-detail-box">
                    <div className='dypxbs'>
                      <div>
                        <p>Do you have a TAN?</p>
                      </div>
                      <div class="extra-bed-radio">
                        <div className='exdrs'>
                          <label>
                            <input type="radio" name="isTanNumber" value="2" checked={isTanNumber === 2} onChange={() => setIsTanNumber(2)} />
                            <span>No</span>
                          </label>
                          <label>
                            <input type="radio" name="isTanNumber" value="1" checked={isTanNumber === 1} onChange={() => setIsTanNumber(1)} />
                            <span>Yes</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {isTanNumber === 1 && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-box">
                            <label htmlFor="tanNumber" className="form-label">Enter TAN</label>
                            <div className='itwac'>
                              <img src={'/assets/img/icons/legal_pointer_icon.png'} alt="Profile" className="form-icon" />
                              <input 
                                type="text" 
                                className={`form-control ${isTanValid === false ? 'is-invalid' : ''}`} 
                                id="tanNumber" 
                                placeholder="Enter the 10 digit TAN" 
                                value={tanNumber} 
                                onChange={(e) => handleInputChange('tanNumber', e.target.value)}
                              />
                            </div>
                            {isTanValid === false && (
                              <div className="invalid-feedback">Please enter a valid TAN (10 characters).</div>
                            )}
                            {errors.tanNumber && <div className="error-text">{errors.tanNumber}</div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <span className='line'></span>
                  <div className="room-detail-box">
                    <div>
                      <p className="rdb-title mb-0">Choose Your Payment Preference</p>
                    </div>
                    <div className="row">
                      <div className="col-md-12 custom-radio-box" style={{ display: 'block', border: 'none' }}>
                        {paymentOptionList.length > 0 ? (
                          paymentOptionList.map((list) => (
                            <div key={list.id} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentOption"
                                id={`paymentOption-${list.id}`}
                                value={list.id}
                                checked={paymentOption === list.id}
                                onChange={() => setPaymentOption(list.id)}
                              />
                              <label className="form-check-label" htmlFor={`paymentOption-${list.id}`}>
                                {list.title}
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className="dropdown-item">No options found</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="faq-sticky">
                <div className='fas-desc-box'>
                  <div className='faq-title-box'>
                    <h4 className='faq-title-txt'>What to do here ?</h4>
                  </div>
                  <p className='faq-desc'>Time to get official, let’s sort out the finances and paperwork. </p>
                </div>
                <hr></hr>
                <div className='faq-txt-box'>
                  <img src='/assets/img/legal_faq_banner.gif' className='img-fluid' alt='' />
                </div>
                {faqs.map((faq, index) => (
                  <div key={index} className='faqs-list-section'>
                    <div
                      onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                      style={{
                        backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                        borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                      }}
                    >
                      {faq.question}
                      <i
                        className={`fas ${
                          activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                        style={{
                          transition: "transform 0.3s ease",
                        }}
                      ></i>
                    </div>
                    {activeIndex === index && (
                      <div className='faqs-ans-box'>
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Submit</button>
        </div>
    </div>
  );
};

export default Legal;
