import React, { useState, useEffect } from "react";
import axios from 'axios';
import params from "../config/Params";
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { useParams } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const AccelerateListing = () => {
  const { listingId } = useParams();
  const [listing, setListing] = useState([]);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedViwOffer, setSelectedViwOffer] = useState(null);

  useEffect(() => {      
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setListing(response.data.updated_property_item);
        } else {
          console.log('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const fetchOffers = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', listingId);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.accOffers}`,
                data: formData
            });
        
            if (response.data.items && Array.isArray(response.data.items)) {
                setOffers(response.data.items);
                setLoading(false);
            } else {
                setError('An error occurred while fetching data.');
            }
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    fetchOffers();
    fetchListingData();
  }, []);

  const activateOffer = async (offerId) => {
    const hostData = getItemFromLocalStorage("hostProfile");
    let formData = new FormData();
    formData.append("token", hostData.host_access_token);
    formData.append('listing_id', listingId);
    formData.append("offer_id", offerId);

    try {
      const response = await axios.post(
        `${params.baseURL}${params.endpoints.activateOffer}`,
        formData
      );

      if (response.data.status === "ok") {
        setShowPopup(false);
        setSelectedOffer(null);
        toast.success(response.data.msg);
      } else {
        setMessage(response.data.message || "Failed to activate offer.");
      }
    } catch (error) {
      setMessage("An error occurred while activating the offer.");
      console.error(error);
    }
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedOffer(null);
  };

  return (
    <div>
        <Helmet>
            <title>Accelerate!</title>
            <meta name='description' content='' />
        </Helmet>
      
        <div className='yl-header'>
            <div>
                <h1 className='ws-title m-0'>FASTEST CUSTOMER ACQUISITION THROUGH ADVANCED AI </h1>
                <p className='ws-desc m-0'>ACCELERATE, THE AI BASED ADVANCED FEATURE IS THE HERE TO HELP YOU</p>
            </div>
        </div>

        <div className="ap-offer-section">
            <div className="apoffbx">
                <div className='acc-box aplbmainbox' style={{marginBottom:'2rem'}}>
                    <div className="aplbbitb">
                      <div className="apimgcibox">
                        <div className='lcibox'>
                            <div className='ac-cover-image'>
                                <img
                                    className="ciso"
                                    src={listing.cover_photo ? listing.cover_photo : '/assets/img/default_listing.jpg'}
                                    alt={listing.listing_title ? listing.listing_title : 'Untitled Listing'}
                                />
                            </div>
                        </div>
                        <img src='https://stcontent.blr1.digitaloceanspaces.com/stays/1729827507_stct.png' alt='' className='cist' />
                      </div>
                      <div>
                        <h4 className='mb-0'>{listing.listing_title ? listing.listing_title : "Unknown"} </h4>
                      </div>
                    </div>
                    <div className="aplbbitb">
                      <img
                        className="img-fluid"
                        src={'/assets/img/banners/pet_terms_banner.png'}
                        alt={'Wire stay offer icon'}
                      />
                    </div>
                </div>

                {offers.length > 0 ? (
                  <div className='row'>
                    {/* First offer in col-md-12 */}
                    <div className='col-md-12 lb' key={offers[0].p_offer_id}>
                      <div>
                        <div className="first-option">
                          <img
                            className="img-fluid aib-oimg"
                            src={'/assets/img/accelerate_offer_1.gif'}
                            alt={'Wire stay offer icon'}
                          />
                          <div className="act-off-bs">
                            <button className="btn btn-black" onClick={() => activateOffer(offers[0].p_offer_id)}>
                              Activate Offer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Remaining offers in col-md-4 */}
                    {offers.slice(1).map((offer) => (
                      <div className='col-md-4 lb' key={offer.p_offer_id} onClick={() => setSelectedViwOffer(offer)}>
                        <div>
                          <div className="other-option">
                            <img
                              className="img-fluid aib-oimg"
                              src={'/assets/img/discount_option_1.png'}
                              alt={'Wire stay offer icon'}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No offer found</p>
                )}
            </div>
        </div>

        {selectedViwOffer && (
          <div className="offer-popup" onClick={() => setSelectedViwOffer(null)}>
            <div className="offer-popup-content" onClick={(e) => e.stopPropagation()}>
              <div class="opc-header">
                <div><h4 className="mb-0">Accelerate!</h4></div>
                <span class="popup-close" onClick={() => setSelectedViwOffer(null)}>×</span>
              </div>
              <img 
                src={'/assets/img/accelerate_offer.gif'} 
                alt="Offer" 
                className="img-fluid" 
              />
              <div className="act-off-bs">
                  <button className="btn btn-black" onClick={() => activateOffer(selectedOffer.p_offer_id)}>
                      Activate Offer
                  </button>
              </div>
            </div>
          </div>
        )}

        {showPopup && selectedOffer && (
            <div className="offer-popup">
                <div className="offer-popup-content">
                    <div class="opc-header">
                        <div><h4 className="mb-0">Accelerate!</h4></div>
                        <span class="popup-close" onClick={closePopup}>×</span>
                    </div>
                    <div class="opc-content">
                        <h2>{selectedOffer.offer_title} {selectedOffer.offer_discription}</h2>
                        <div className="act-off-bs">
                            <button className="btn btn-black" onClick={() => activateOffer(selectedOffer.p_offer_id)}>
                                Activate Offer
                            </button>
                        </div>
                        <div>
                            <h4>Terms and Conditions</h4> 
                            <div style={{textAlign:'left'}} dangerouslySetInnerHTML={{ __html: selectedOffer.offer_tnc }} />
                        </div>
                    </div>
                </div>
            </div>
        )}

    </div>
  );
};

export default AccelerateListing;
