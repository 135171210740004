import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';

const AccelerateProgram = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchListings = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getMyListings}`,
                    data: formData
                });
            
                if (response.data.items && Array.isArray(response.data.items)) {
                    setListings(response.data.items);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchListings();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading listings: {error.message}</p>;
    }

    return (
        <div>
            <Helmet>
                <title>Accelerate Your Program</title>
                <meta name="description" content="" />
            </Helmet>
            <div className='yl-header'>
                <div>
                    <h1 className='ws-title m-0'>Accelerate!</h1>
                    <p className='ws-desc m-0'>Choose listing and accelerate your sales.</p>
                </div>
            </div>

            {listings.length > 0 ? (
                <div className='row'>
                    {listings.map((listing) => (
                        <div className='col-md-4 lb mb-3' key={listing.stay_listing_id}>
                            <a href={`/accelerate-listing/${listing.stay_listing_id}`}>
                                <div className='acc-box' style={{marginBottom:'10px'}}>
                                    <div className="apimgcibox">
                                        <div className='lcibox'>
                                            <div className='ac-cover-image'>
                                                <img
                                                    className="ciso"
                                                    src={listing.cover_photo ? listing.cover_photo : '/assets/img/default_listing.jpg'}
                                                    alt={listing.listing_title ? listing.listing_title : 'Untitled Listing'}
                                                />
                                            </div>
                                        </div>
                                        <img src='https://stcontent.blr1.digitaloceanspaces.com/stays/1729827507_stct.png' alt='' className='cist' />
                                    </div>
                                    <h4 className='mb-0'>{listing.listing_title ? listing.listing_title : "Unknown"} </h4>
                                </div>
                                <div className='acc-box'>
                                    <span className='acc-btn-txt'>Accelerate</span>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No listings found</p>
            )}
        </div>
    );
};

export default AccelerateProgram;
